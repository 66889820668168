
import { importSync as i } from '@embroider/macros';
let d = window.define;
d("codecrafters-frontend/templates/demo", function(){ return i("codecrafters-frontend/templates/demo.hbs");});
d("codecrafters-frontend/controllers/demo", function(){ return i("codecrafters-frontend/controllers/demo.ts");});
d("codecrafters-frontend/routes/demo", function(){ return i("codecrafters-frontend/routes/demo.ts");});
d("codecrafters-frontend/templates/demo/code-mirror", function(){ return i("codecrafters-frontend/templates/demo/code-mirror.hbs");});
d("codecrafters-frontend/controllers/demo/code-mirror", function(){ return i("codecrafters-frontend/controllers/demo/code-mirror.ts");});
d("codecrafters-frontend/routes/demo/code-mirror", function(){ return i("codecrafters-frontend/routes/demo/code-mirror.ts");});
d("codecrafters-frontend/templates/demo/dark-mode-toggle", function(){ return i("codecrafters-frontend/templates/demo/dark-mode-toggle.hbs");});
d("codecrafters-frontend/controllers/demo/dark-mode-toggle", function(){ return i("codecrafters-frontend/controllers/demo/dark-mode-toggle.ts");});
d("codecrafters-frontend/routes/demo/dark-mode-toggle", function(){ return i("codecrafters-frontend/routes/demo/dark-mode-toggle.ts");});
d("codecrafters-frontend/templates/demo/file-contents-card", function(){ return i("codecrafters-frontend/templates/demo/file-contents-card.hbs");});
d("codecrafters-frontend/controllers/demo/file-contents-card", function(){ return i("codecrafters-frontend/controllers/demo/file-contents-card.ts");});
d("codecrafters-frontend/routes/demo/file-contents-card", function(){ return i("codecrafters-frontend/routes/demo/file-contents-card.ts");});
d("codecrafters-frontend/routes/demo/index", function(){ return i("codecrafters-frontend/routes/demo/index.ts");});
